<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.profile.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'Profiles')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
              </div>
            </CCol>
          </div>

        </CCardHeader>
        <!--        {{ showAlert }}-->
        <!--        <alerts :message="alertMessage" type="success" :v-if="showAlert"/>-->
        <CCardBody>

          <transition name="fade">
            <CRow v-if="toggleFilterValue">

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.profile.filter.category }}</label>
                  <v-select :options="categoryOptions" v-model="profile.category"
                            @option:selected="getCompanyIndividualUsers"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.profile.filter.type }}</label>
                  <v-select
                      :options="typesOptions"
                      v-model="profile.type"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3" style="display:none">
                <div class="form-group">
                  <label class="form__label">{{ $lang.common.filter.companyIndividualUser }}</label>
                  <v-select :options="userOptions" v-model="profile.user"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.profile.filter.profileVerified }}</label>
                  <v-select :options="profileVerifiedOptions" v-model="profile.profileVerified"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.profile.filter.industry }}</label>
                  <v-select :options="industryOptions"
                            :resetOnOptionsChange="true"
                            :clearSearchOnSelect="true"
                            v-model="profile.industry"
                            @option:selected="getSubIndustry">
                  </v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.profile.filter.subIndustry }}</label>
                  <v-select multiple :options="subIndustryOptions" v-model="profile.subIndustry"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.profile.filter.country }}</label>
                  <v-select :options="countryOptions" v-model="profile.country" @option:selected="loadState">
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.profile.filter.state }}</label>
                  <v-select :options="StateOptions" v-model="profile.state" @option:selected="loadCity"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.profile.filter.city }}</label>
                  <v-select :options="cityOptions"
                            v-model="profile.city">
                  </v-select>
                </div>
              </CCol>


              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px;">
                  <CButton type="submit" size="sm" color="primary" @click="applyFilter()"
                           v-c-tooltip="$lang.common.filter.button.filter">
                    {{ $lang.common.filter.button.filter }}
                  </CButton> &nbsp;
                  <CButton color="danger" size="sm" @click="resetFilter()"
                           v-c-tooltip="$lang.common.filter.button.cancel">
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <CAlert color="success" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}</CAlert>

          <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable"
                          @loaded="exports(self,'Excel',module,'Profiles')" name="saveStateTable">
                          
            <template #isVerified="data">
              <CBadge :color="verifiedStatusBudget(data.row.isVerified)">{{ data.row.isVerified }}</CBadge>
            </template>
            <template #emailStatus="data">
              <CBadge :color="statusBudget(data.row.emailStatus)">{{ data.row.emailStatus }}</CBadge>
            </template>
            <template #JobTitleStatus="data">
              <CBadge :color="statusBudget(data.row.JobTitleStatus)">{{ data.row.JobTitleStatus }}</CBadge>
            </template>
            <template #planStatus="data">
              <CBadge :color="statusBudget(data.row.planStatus)">{{ data.row.planStatus }}</CBadge>
            </template>


            <!--                      <template #company="data">-->
            <!--                        <Avatar :image=data.row.avatar :defaultImage=data.row.avatar :content=data.row.company :length="20" />-->
            <!--                      </template>-->
            <!--                      <template #name="data">-->
            <!--                        <span v-c-tooltip="data.row.name">{{trimfunction(data.row.name,20)}}</span>-->
            <!--                      </template>-->
            <template #name="data">
              <Avatar v-if="data.row.type==='Individual'"
                      :image="data.row.avatar"
                      :defaultImage="data.row.avatar"
                      :content="data.row.name"
                      :isProfileListPage="true"
                      :length="50"
              />
              <span v-else> <center> --- </center>  </span>
            </template>
            <template #company="data">
              <Avatar v-if="data.row.type==='Company'"
                      :image="data.row.avatar"
                      :defaultImage="data.row.avatar"
                      :content="data.row.company"
                      :isProfileListPage="true"
                      :length="50"
              />
              <span v-else> <center> --- </center>  </span>
            </template>
            <template #title="data">
              <span :title="data.row.title">{{ trimfunction(data.row.title, 20) }}</span>
            </template>
            <template #actions="data">

              <CButton color="primary" v-c-tooltip="$lang.buttons.general.crud.view_user"
                       v-on:click="viewTableRow(data.row.userId)" v-if='checkPermission("list-profile")'>
                <i class="fas fa-user"></i>
              </CButton>

              <CButton color="primary" v-c-tooltip="$lang.buttons.general.view_resource"
                       v-on:click="viewAllTableRow(data.row.userId)" v-if='checkPermission("list-profile")'>
                <i class="fas fa-eye"></i>
              </CButton>
              <!--                            <CButton color="danger" v-c-tooltip="$lang.buttons.general.crud.delete" v-on:click="deletePopup(data.row.id)" v-if='checkPermission("delete-profile")' >-->
              <!--                                <i class="fas fa-trash"></i>-->
              <!--                            </CButton>-->
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>

  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import VueJsonToCsv from "vue-json-to-csv";
import VueSweetalert2 from 'vue-sweetalert2';
import { ServerTable } from 'vue-tables-2';
import BeforeFilter from "../BeforeFilterOpen";
import Avatar from "/src/components/Avatar";
import { Mixin } from "/src/mixins/index.js";
import store from "/src/store/store.js";
import { city, companiesTypes, country, industry, profile, profileSubIndustry, state, users } from "/src/store/url.js";
import options from "/src/validations/validations.js";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false)
Vue.use(VueForm, options);

export default {
  name: 'ProfilePage',
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
    Avatar

  },
  data() {
    return {
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false, /* for filter toggle*/
      err_msg: '',
      largeModal: false,
      submitType: '',
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: '',
      items: [],
      industryOptions: [],
      subIndustryOptions: [],
      selectedIndustry: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      userOptions: [],
      categoryOptions: [],
      typesOptions: [],
      profileVerifiedOptions: [],
      activePage: 1,
      listUrl: '/users/profiles/list',
      module: profile,
      moduleIndustry: industry,
      moduleSubIndustry: profileSubIndustry,
      moduleUser: users, //Companies list
      moduleCompanyTypes: companiesTypes,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      profile: {
        name: '',
        isVerified: '',
        city: '',
        country: '',
        state: '',
      },
      columns: [
          'type',
          "companyType",
          "company",
          "name",
          "isVerified",
          "emailStatus",
          "JobTitleStatus",
/*          "planName",
          "startDate",
          "endDate",
          "planStatus",*/
          'createdAt',
          "actions"],
      data: [],
      options: {
        saveState:true,
        headings: {
          type: this.$lang.profile.table.type,
          companyType: this.$lang.profile.table.companyType,
          company: this.$lang.profile.table.company,
          name: this.$lang.profile.table.freelancer,
          isVerified: this.$lang.profile.table.profileStatus,
          emailStatus: this.$lang.profile.table.emailStatus,
          JobTitleStatus: this.$lang.profile.table.JobTitleStatus,
          createdAt: this.$lang.profile.table.createdAt,
         /* planName: this.$lang.profile.table.planName,
          startDate: this.$lang.profile.table.starDate,
          endDate: this.$lang.profile.table.endDate,
          planStatus: this.$lang.profile.table.planStatus,
          title: this.$lang.profile.table.title,*/

          actions: this.$lang.common.general.actions,
        },
        editableColumns: ['name', 'isVerified'],
        sortable: ['name', 'isVerified','createdAt'],
        filterable: ['name', 'isVerified','createdAt']
      }
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    localStorage.removeItem('iAmUser'); // if user then i will hide some information
    self.profileVerifiedOptions.push(
        {value: '1', label: 'Non-Verified'},
        {value: '2', label: 'Verified'},
        {value: '3', label: 'Blocked'}
    );
    self.categoryOptions.push(
        {value: 'Company', label: 'Company'},
        {value: 'Individual', label: 'Individual'},
    );

    axios.get(this.listUrlApi(this.moduleUser))
        .then((response) => {
          response.data.data.map(function (value, key) {
            self.userOptions.push({value: value.id, label: value.name});
          });
        });

    axios.get(this.listUrlApi(this.moduleCompanyTypes))
        .then((response) => {
          response.data.data.map(function (value, key) {
            self.typesOptions.push({value: value.id, label: value.title});
          });
        });

    axios.get(this.listUrlApi(this.moduleIndustry))
        .then((response) => {
          response.data.data.map(function (value, key) {
            // self.industryLists.push({value: value.id, label: value.title});
            if (value.industryId === "") {
              self.industryOptions.push({value: value.id, label: value.industry});
            }
          });
        });


    axios.get(this.listUrlApi(this.moduleCountry))
        .then((response) => {
          response.data.data.map(function (value, key) {
            self.countryOptions.push({value: value.id, label: value.name});
          });
        });


    this.$root.$on('alert', (arg1, arg2) => {
    });
    if (localStorage.getItem('showAlert') !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem('showAlert', '');
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string[0].toUpperCase() + string.slice(1);
    },

    verifiedStatusBudget(str) {
            if (str === 'Verified') {
                return 'success';
            } else if (str === 'Pending') {
                return 'warning';
            } else if (str === 'Rejectd') {
                return 'danger';
            } else if (str === 'Draft' || str === 'Registered') {
                return 'info';
            } else {
                return 'info';
            }
        },
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    //Filer
    loadState() {
      let self = this;
      const postData = {'countryId': self.profile.country.value};
      self.profile.state = {};
      self.profile.city = {};
      axios.post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {'stateId': self.profile.state.value};
      self.profile.city = {};
      axios.post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },
    getSubIndustry() {
      let self = this;
      if (self.profile.industry.value > 0) {
        const postData = {'industry_id': self.profile.industry.value};
        axios.post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.subIndustryOptions = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.subIndustryOptions.push({value: value.id, label: value.SubIndustry});
                }
              });
            });
      }
    },
    //END

    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel
      }).then((result) => { // <--
        if (result.value) { // <-- if accepted
          let self = this;
          axios.delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                  // self.$router.go()
                }
              });
        }
      });
    },
    createTableRow() {
      this.$router.push({name: 'Profile Create'})
    },
    getCompanyIndividualUsers() {

    },
    editTableRow(item) {
      this.$router.push({path: `profile/edit/${(item)}`})
    },
    viewTableRow(item) {
      const routeData = this.$router.resolve({path: `profile/view/${(item)}`});
      window.open(routeData.href, '_blank');

      // this.$router.push({path: `profile/view/${(item)}`})
    },
    viewAllTableRow(item) {
      const routeData = this.$router.resolve({path: `profile/all/${(item)}`});
      window.open(routeData.href, '_blank');
      // this.$router.push({path: `profile/all/${(item)}`})
    },
    verificationTableRow(item) {
      this.$router.push({path: `profile/verification/${(item)}`})
    },
    profileUpdateRequestListTableRow(item) {
      this.$router.push({path: `profile/update-requests/${(item)}`})
    },

    viewSocialLinkTableRow(item) {
      this.$router.push({path: `profile/social-links/${(item)}`})
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`})
    },
    pageChange(val) {
      this.$router.push({query: {page: val}})
    },
    loadData() {
      let self = this;
      axios.get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },


    applyFilter() {
      let industry = '';
      let user = '';
      let country = "";
      let state = "";
      let city = "";
      let profileVerified = '';
      let category = '';
      let type = ""; //large,Micro,Consultance,Freelancer

      let getSubIndustries = [];

      if (this.profile.industry != null) {
        industry = this.profile.industry.value;
      }
      if (this.profile.subIndustry != null) {
        this.profile.subIndustry.map(function (value, key) {
          getSubIndustries[key] = value.value;
        });
      }
      if (this.profile.user != null) {
        user = this.profile.user.value;
      }
      if (this.profile.category != null) {
        category = this.profile.category.value;
      }
      if (this.profile.type != null) {
        type = this.profile.type.value;
      }
      if (this.profile.country != null) {
        country = this.profile.country.value;
      }
      if (this.profile.state != null) {
        state = this.profile.state.value;
      }
      if (this.profile.city != null) {
        city = this.profile.city.value;
      }
      if (this.profile.profileVerified != null) {
        profileVerified = this.profile.profileVerified.value;
      }
      const reqObj = {
        industry: industry,
        subIndustry: getSubIndustries,
        user: user,
        category: category,
        type: type,
        country: country,
        state: state,
        city: city,
        profileVerified: profileVerified,
      };
      store.commit('showLoader', true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();

    },

    resetFilter() {
      this.profile.industry = "";
      this.profile.subIndustry = "";
      this.profile.user = {};
      this.profile.country = {};
      this.profile.state = {};
      this.profile.city = {};
      this.profile.profileVerified = {}
      store.commit('showLoader', true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },

  }
}
</script>
